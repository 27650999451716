<!--

      This view displays the list of recent orders. It is reachable from within
      the profile tab.

 -->

<template>
  <Page title='Alle Produkte' hideback="true" :shoppingList="true">
    <AllProducts />
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import AllProducts from '@/components/shopping/AllProducts.vue'

export default {
  name: 'shopping',
  components: {
    Page,
    AllProducts
  }
}
</script>